
















import { Component, Vue } from 'vue-property-decorator'
import Player from '@/components/common/Player.vue'
import HighlightText from '@/components/HighlightText.vue'

@Component({
  components: {
    Player,
    HighlightText
  }
})
export default class Step4 extends Vue {
  private isText = false

  get videoUrls (): Array<string> {
    return this.$store.state.video.videoUrls[6]
  }
}
